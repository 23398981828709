<template>
    <div class="card mb-5 mb-xl-10">
        <editor
          v-model="report.report_content"
          :init="{
               height: 600,
              forced_root_block: false,
              verify_html: false,
              cleanup: false,
              cleanup_on_startup: false,
              valid_children : '+body[style]',
              relative_urls : false,
              remove_script_host : false,
              menubar: false,
              convert_urls: false,
              toolbar1:
                 'undo redo | formatselect | bold italic backcolor | \
                 alignleft aligncenter alignright alignjustify |  fontselect fontsizeselect |',
              toolbar2:'bullist numlist outdent indent table | code save imagetools image | removeformat | help',
              plugins:  'code imagetools image table'}"
        >
        </editor>
    </div>
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button
        type="button"
        id="admin_report_submit"
        ref="submitButton"
        class="btn btn-primary"
        @click="submitChange()"
      >
        <span class="indicator-label"> Save Changes </span>
        <span class="indicator-progress">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
    import Editor from "@tinymce/tinymce-vue";
    import ApiService from "@/core/services/ApiService";
    import { useRoute } from "vue-router";
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import Swal from "sweetalert2";
    import router from "@/router";

    export default defineComponent({
        name: "admin-report-editor",
        components: {
            Editor
        },
        setup() {
            const route = useRoute();
            const id : number = +route.params.id; 
            const report = ref({} as any);          
            const getReport = async() =>{
              ApiService.setHeader();
              await ApiService.get("report/"+id)
                    .then(({ data }) => {
                        report.value = data.data;
                    })
                    .catch(({ response }) => {
                      console.log('getReport error => '+response.data.errors);
                    });
            }

            const submitChange = () =>{
              ApiService.setHeader();
              ApiService.update("report",report.value.id, report.value)
              .then(() =>{
                  Swal.fire({
                    text: "Template has been updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    if(id == 1){
                        router.push({ name: "invoice" });
                    }
                    else{
                        router.push({ name: "admin-report" });
                    }
                });
              })
              .catch(({ response }) => {
                console.log('submitChange error => '+response.data.errors);
              });
            }

            onBeforeMount(()=>{
              getReport();
            })

            onMounted(() => {
              setCurrentPageBreadcrumbs("Edit Report", ["Admin Report"]);
            });

            return {
              report,
              submitChange
            };
        },
    });
</script>