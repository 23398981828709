
    import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
    import Editor from "@tinymce/tinymce-vue";
    import ApiService from "@/core/services/ApiService";
    import { useRoute } from "vue-router";
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import Swal from "sweetalert2";
    import router from "@/router";

    export default defineComponent({
        name: "admin-report-editor",
        components: {
            Editor
        },
        setup() {
            const route = useRoute();
            const id : number = +route.params.id; 
            const report = ref({} as any);          
            const getReport = async() =>{
              ApiService.setHeader();
              await ApiService.get("report/"+id)
                    .then(({ data }) => {
                        report.value = data.data;
                    })
                    .catch(({ response }) => {
                      console.log('getReport error => '+response.data.errors);
                    });
            }

            const submitChange = () =>{
              ApiService.setHeader();
              ApiService.update("report",report.value.id, report.value)
              .then(() =>{
                  Swal.fire({
                    text: "Template has been updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    if(id == 1){
                        router.push({ name: "invoice" });
                    }
                    else{
                        router.push({ name: "admin-report" });
                    }
                });
              })
              .catch(({ response }) => {
                console.log('submitChange error => '+response.data.errors);
              });
            }

            onBeforeMount(()=>{
              getReport();
            })

            onMounted(() => {
              setCurrentPageBreadcrumbs("Edit Report", ["Admin Report"]);
            });

            return {
              report,
              submitChange
            };
        },
    });
